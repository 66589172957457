import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore"
import { initializeAnalytics, isSupported } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAB2anr7HylFZprpemGVpyn5azu2k1Q2xU",
  authDomain: "galapagos-waddle.firebaseapp.com",
  projectId: "galapagos-waddle",
  storageBucket: "galapagos-waddle.appspot.com",
  messagingSenderId: "474275324461",
  appId: "1:474275324461:web:8117df45546bc18b6f4ea4",
  measurementId: "G-04MNK6VC01"
};

const analyticsConfig = {
  config:{
    'cookie_flags': "SameSite=None; Secure",
    'send_page_view': false
  }
}



const app = initializeApp(firebaseConfig);
const analytics = null;
//const analytics = isSupported()? initializeAnalytics(app, analyticsConfig): null;
export const firestore = getFirestore(app)
export {analytics}