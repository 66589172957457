import './App.css'
import Index from './unifycom/index'
import {useState, useEffect} from 'react';


function App() {
  const [demo, setDemo] = useState(false);
  const [demoImg, setDemoImg] = useState(null)

  useEffect(()=> {
    const queryParameters = new URLSearchParams(window.location.search)
    const demo = queryParameters.get("demo");
    setDemoImg(demo);
    setDemo(true);
  }, [])
  return (
    <div className="App">
      {demo && <img src={demoImg} className="demo-image"/>}
      <Index/>  
      
    </div>
  );
}

export default App;
