import CloseImage from './close.svg'
import {useState, useEffect} from 'react'
import ReactPlayer from 'react-player/file'
import { VolumeMuteFill, VolumeUpFill, PlayFill, PauseFill, X } from 'react-bootstrap-icons'

function VideoPlayer(props) {
	const [audioToggle, setAudioToggle] = useState(false);
	const [playingToggle, setPlayingToggle] = useState(true);
	const [height, setHeight] = useState(0);
	const [width, setWidth] = useState(0);

	useEffect(() => {
		if(props.height/props.width > 1.777) {
			setWidth(props.width);
			setHeight(props.width*16/9)
		}
		else {
			setWidth(props.height*9/16);
			setHeight(props.height)
		}
	}, [props.height,props.width])

	return (
		<div className="chat-video-wrapper">
			<div>
			<ReactPlayer muted={audioToggle} width={width} height={height} playing={playingToggle} url={props.videoUrl} onEnded={() => {setPlayingToggle(false); props.closeVideo();}}/>
			<div className="video-controls">
				<button className="video-controls-btn" onClick={() => {setPlayingToggle(!playingToggle)}}>{playingToggle?<PauseFill/>:<PlayFill/>}</button>
				<button className="video-controls-btn" onClick={() => {setAudioToggle(!audioToggle)}}>{audioToggle?<VolumeMuteFill/>:<VolumeUpFill/>}</button>
				<button className="video-controls-btn close" onClick={() => {setPlayingToggle(false); props.closeVideo()}}><X/></button>
			</div>
			</div>
		</div>
		)
}

export default VideoPlayer;