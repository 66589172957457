
import CloseImage from './close.svg'
import OrnamentImage from './ornament.svg'
import {useState, useEffect, useRef} from 'react'

import VideoPlayer from './videoPlayer';
import TriggerList from './triggerList';
import EligibilityCheck from './eligibilityCheck';
import ChatForm from './chatForm';
import {TextWithInput} from './interactions/TextWithInput'

import {sendEvent} from '../tracking/dataLayerHandler'

import PlayIcon from '../assets/play_circle.svg'


import DurationIcon from './duration.svg'
import LanguageIcon from './language.svg'
import TeachingIcon from './teaching.svg'
import TuitionIcon from './tuition.svg'

const CHAT_DELAY = 2000;

function ChatWidget(props) {
	const [currentSequence, setCurrentSequence] = useState(null);
	const [isFlowAForm, setIsFlowAForm] = useState(false);
	const [hubspotIntegration, setHubspotIntegration] = useState({})

	const [submitForm, setSubmitForm] = useState(false);

	const [formAnswers, setFormAnswers] = useState([])
	const [currentInteraction, setCurrentInteraction] = useState(null)

	const [sequenceStatus, setSequenceStatus] = useState("END");
	const [chatHistory, setChatHistory] = useState([]);
	const [defaultTriggers, setDefaultTriggers] = useState([])
	const [allTriggers, setAllTriggers] = useState([])
	const [onVideoEnd, setOnVideoEnd] = useState(null)

	const [showTriggerList, setShowTriggerList] = useState(false)

	const [videoPlaying, setVideoPlaying] = useState(false);
	const [currentVideo, setCurrentVideo] = useState(null);

	const [showEligibility, setShowEligibility] = useState(false);
	const [onEligibilityEnd, setOnEligibilityEnd] = useState(null)

	const myCallbacksList = useRef([]);
 	const chatEndRef = useRef(null)

 	const wrapperRef = useRef(null)
 	const [chatHeight, setChatHeight] = useState(0);
 	const [chatWidth, setChatWidth] = useState(0);

 	const [smallHeader, setSmallHeader] = useState(false);

	

	useEffect(() => {
		//Init chat with History (WIP) and initial message
		const initialSequence = props.flows.find((seq) => {
			return seq["default"]
		})
		setCurrentSequence(initialSequence);

		const defaultSequences= props.flows.filter((seq) => {
			return seq.defaultTrigger;
		})
		const triggers = [];
		defaultSequences.map(seq => {
			triggers.push({sequenceId: seq.id, trigger: seq.trigger, type: seq.triggerType})
		})
		setDefaultTriggers(triggers)


		//All Triggers
		const auxTriggers = [];
		props.flows.map(seq => {
			if(!seq.default)
				auxTriggers.push({sequenceId: seq.id, trigger: seq.trigger, type: seq.triggerType, category: seq.category, defaultTrigger: seq.defaultTrigger})
		})
		setAllTriggers(auxTriggers)
		
	}, []);	

	useEffect(() => {
		if(currentSequence)
			startSequence();
	}, [currentSequence])

	useEffect(() => {
		if(chatHistory.length > 0 && !smallHeader)
			setSmallHeader(true)
	}, [chatHistory])

	useEffect(() => {
		async function submit(data) {
			try {
				if(hubspotIntegration.hubspotPortalId)
					data.hubspotIntegration = hubspotIntegration
			}
			catch(e) {
				sendEvent("error", {name: "FORM_SUBMIT_INTEGRATION", form: "form id: " + data.formId + "error: "+ e.message }, props.organizationId);
			}
			try {
				props.handleFormSubmit(data)
				sendEvent("form_submission", {form: data.formId}, props.organizationId);
			}
			catch(e) {
				sendEvent("error", {name: "FORM_SUBMIT_INTERNAL", form: "form id: " + data.formId + "error: "+ e.message }, props.organizationId);
			}
		}
		if(submitForm) {
			if(currentSequence.type != "form")
				return;
			const data = {formId: currentSequence.id, answers: formAnswers};
			
			submit(data);
		}
	}, [submitForm])

	useEffect(() => {
		if(props.trigger) {
			triggerSequence(props.trigger.trigger, props.trigger.sequenceId, props.trigger.type);

			sendEvent("interaction", {name: `trigger`, interaction_trigger_name: props.trigger.trigger, interaction_type: "trigger"}, props.organizationId);
		}
	}, [props.trigger])

	useEffect(() => {
		setTimeout(function() {
			setChatHeight(wrapperRef.current.clientHeight);
			setChatWidth(wrapperRef.current.clientWidth);
		}.bind(this), 500)
	}, [props.active])

	const setHistoryWithCallback = (newState, callback) => {
	  setChatHistory(newState);
	  if(callback) myCallbacksList.current.push(callback)
	}

	useEffect(() => {
		myCallbacksList.current.forEach((callback) => callback())
  		myCallbacksList.current = [];
  		scrollToBottom();
	}, [chatHistory])

	const scrollToBottom = () => {
	    chatEndRef.current?.scrollIntoView({ behavior: "smooth" })
	 }

	function startSequence() {
		setSequenceStatus("ONGOING")

		//Set Flow as a Form to start collecting answers
		if(currentSequence.type == "form") {
			setIsFlowAForm(true)
			if(currentSequence.hubspotPortalId)
				setHubspotIntegration({hubspotPortalId: currentSequence.hubspotPortalId, hubspotFormId: currentSequence.hubspotFormId})
		}
		else {
			setIsFlowAForm(false)
			setHubspotIntegration({})
		}
		setFormAnswers([])

		sendEvent("interaction", {name: `trigger`, interaction_trigger_name: currentSequence.trigger, interaction_type: "trigger"}, props.organizationId);

		//Get the first interaction of the flow to start
		const firstInteraction = currentSequence.interactions.find((seq) => {
			return seq.first
		});
		if(firstInteraction)
			setTimeout(function() {runSequence(firstInteraction.id);}.bind(this),700)
		else
			setSequenceStatus("END")
	}

	function triggerSequence(trigger, newSequenceId, type) {

		if(trigger)
			if(!isFlowAForm)
				sendEvent("interaction", {name: `user_action`, interaction_trigger_name: trigger, interaction_type: "user_interaction"}, props.organizationId);

		if(type == "END"){
			setSequenceStatus("END");
			if(isFlowAForm) {
				//form cancelled
				setIsFlowAForm(false)
				setFormAnswers([])
			}
			setTimeout(function() {scrollToBottom()}.bind(this), 100)
		}
		else if(type == "TRIGGER_INTERACTION") {
			if(trigger) {

				setChatHistory([...chatHistory, {type: "trigger", text: trigger}]);
				if(isFlowAForm){
					let answer = {id: currentInteraction.id, text: currentInteraction.content.text ,answer: trigger}
					if(currentInteraction.formAttributeName)
						answer.fieldName = currentInteraction.formAttributeName;
					setFormAnswers([...formAnswers, answer]);
				}

			}
			runSequence(newSequenceId);
		}
		else if(type == "SHOW_ALL_TRIGGERS") {
			setSequenceStatus("END");
			setShowTriggerList(true);
		}
		else if(type == "ELIGIBILITY") {
			setSequenceStatus("WAITING");
			setOnEligibilityEnd(newSequenceId)
			setShowEligibility(true)
		}
		else {
			if(trigger)
				setChatHistory([...chatHistory, {type: "trigger", text: trigger}])
			
			if(currentSequence){
				if(newSequenceId == currentSequence.id) {
					startSequence();
				}
				else {
					const newSequence = props.flows.find((seq) => {
						return seq.id == newSequenceId;
					})
					setCurrentSequence(newSequence)
				}
			}
			else {
					const newSequence = props.flows.find((seq) => {
						return seq.id == newSequenceId;
					})
					setCurrentSequence(newSequence)
				}

		}
	}


	function runSequence(nextInteraction) {

		const interaction = currentSequence.interactions.find((seq) => {
			return seq.id == nextInteraction;
		});
		setCurrentInteraction(interaction);

		if(interaction) {
			if(interaction.type != "action_required") {

				setHistoryWithCallback(chatHistory => [...chatHistory, {...interaction.content, type: interaction.type, next: interaction.next}], (currentChatHistory) => {

					
					if(interaction.next == "wait" || interaction.waitAction || interaction.type.includes("_options") || interaction.type.includes("_input")) {
						setSequenceStatus("WAITING")
						setTimeout(function() {scrollToBottom()}.bind(this), 100)
					}
					else if (interaction.next == "end") {
						setSequenceStatus("END")
						if(isFlowAForm) {
							setSubmitForm(true)
						}
						setTimeout(function() {scrollToBottom()}.bind(this), 100)
						//WIP onSequenceEnd
					}
					else {
						setSequenceStatus("ONGOING")
						setTimeout(function() {runSequence(interaction.next)}.bind(this),CHAT_DELAY)
					}
				})
				
			}
		}
		else {
			setSequenceStatus("END")
		}

	}

	function openVideo(videoUrl, interaction) {
		setCurrentVideo(videoUrl);
		setVideoPlaying(true);
		setOnVideoEnd(interaction)
	}

	function closeVideo() {
		setVideoPlaying(false);
		if(onVideoEnd)
			triggerSequence(onVideoEnd.trigger, onVideoEnd.interactionId, onVideoEnd.type)
	}

	function closeEligibility(forceEnd) {
		setShowEligibility(false)
		if(forceEnd) {
			setSequenceStatus("END");
			setTimeout(function() {scrollToBottom()}.bind(this), 100);
		}
	}

	function linkClick(url) {

		sendEvent("chat_link_clicked", {flow_id: currentSequence.id, chat_link: url}, props.organizationId);
		let _url = new URL(url)
		_url.searchParams.append('click_source', "uni_widget_link_click");
		window.open(_url.href, '_blank')
	}


	return (
		<div>

			<div ref={wrapperRef} className={`unify-chat-wrapper ${props.active? "active": ""}`}>
			<div className="">
				<div className={`unify-chat-header ${smallHeader? "unify-chat-header--small":""}`}>
				<img className="ornament" src={OrnamentImage}/>
				<img className="avatar" src={props.assistant.avatar}/>
				<h2 className="greeting">{props.assistant.greeting} I'm {props.assistant.name}</h2>
				<p className="sub-greeting">Welcome to <strong>{props.client.name}</strong>, How can I help you today?</p>
				</div>
				<div className="unify-chat">
				<ul className="list-unstyled d-flex flex-column">
					{chatHistory.map((event, i) => {
						switch(event.type) {
							case "text":
								return (<li key={i} className="chat-text assistant">{event.text}</li>)
								break;
							case "text_video":
								return (<li key={i} className="chat-text assistant">{event.text}
										<div className="chat-video-container" onClick={(() => {openVideo(event.video, event.onVideoEnd)})}>
											<img className="video-thumbnail" src={event.videoImage}></img>
											<img className="play" src={PlayIcon}/>
										</div>
										{event.triggers && event.triggers.map((trigger, e) => {
											return <button key={e} className={`chat-btn ${(sequenceStatus=="END" || i+1 != chatHistory.length)? "disabled":""}`} onClick={() => {if(sequenceStatus!= "END" && i+1 == chatHistory.length) triggerSequence(trigger.trigger, trigger.interactionId, trigger.type)}}>{trigger.trigger}</button>
										})}
										<button className={`chat-btn ${(sequenceStatus=="END" || i+1 != chatHistory.length)? "disabled":""}`}  onClick={() => {triggerSequence("", "", "SHOW_ALL_TRIGGERS")}}>Ask something else</button>
									</li>)
								break;
							case "action_required":
								return (<li key={i} className="chat-text assistant">{event.text}</li>)
								break;
							case "text_with_link":
								return (<li key={i} className="chat-text assistant">{event.text}
										<a className="link-card-container" onClick={() => linkClick(event.url)}>
										<figure><img src={event.thumbnail}/></figure>
										<div className="link-content"><h6>{event.title}</h6><p>{event.description}</p></div>
										</a>
									</li>)
								break;
							case "text_with_options":
								return (<li key={i} className="chat-text assistant">{event.text}
									{event.options && event.options.map((trigger, e) => {
											return <button key={e} className={`chat-btn ${(sequenceStatus=="END" || i+1 != chatHistory.length)? "disabled":""}`} onClick={() => {if(sequenceStatus!= "END" && i+1 == chatHistory.length) {  let next = trigger.next == "default"? event.next: trigger.next;   triggerSequence(trigger.trigger, next, trigger.nextType)} }}>{trigger.trigger}</button>
										})}
										<button className={`chat-btn ${(sequenceStatus=="END" || i+1 != chatHistory.length)? "disabled":""}`}  onClick={() => {triggerSequence("", "", "SHOW_ALL_TRIGGERS")}}>Ask something else</button>
									</li>)
								break;
							case "text_with_input":
								return(<li key={i}  className="chat-text assistant">{event.text} <TextWithInput next={event.next} interaction={event} triggerSequence={triggerSequence}/> <button className={`chat-btn ${(sequenceStatus=="END" || i+1 != chatHistory.length)? "disabled":""}`}  onClick={() => {triggerSequence("", "", "SHOW_ALL_TRIGGERS")}}>Ask something else</button></li>)
								break;
							case "chat_form":
								return(<li key={i}  className="chat-text assistant">{event.text} <ChatForm portalId={event.portalId} formId={event.formId} triggerSequence={triggerSequence}/> <button className={`chat-btn ${(sequenceStatus=="END" || i+1 != chatHistory.length)? "disabled":""}`}  onClick={() => {triggerSequence("", "", "SHOW_ALL_TRIGGERS")}}>Ask something else</button></li>)
								break;
							case "trigger":
								return (<li key={i} className="chat-text user">{event.text}</li>)
								break;
						}
					})}

					{sequenceStatus == "ONGOING" && <li className="chat-text assistant writing"><div className="spinner"><div className="bounce1"></div><div className="bounce2"></div><div className="bounce3"></div></div></li>}
				
					{defaultTriggers.map((trigger, i) => {
						return (sequenceStatus == "END" && <li className="interaction" onClick={() => triggerSequence(trigger.trigger, trigger.sequenceId, trigger.type)} key={i}> {trigger.trigger}</li>)
					})}
					{sequenceStatus == "END" && <li className="interaction" onClick={() => triggerSequence("", "", "SHOW_ALL_TRIGGERS")}>Ask something else</li>}
					<li ref={chatEndRef} />

					
				</ul>
				</div>
				{showEligibility && <EligibilityCheck triggerSequence={triggerSequence} onEligibilityEnd={onEligibilityEnd} close={closeEligibility}/>}
				{videoPlaying && <VideoPlayer height={chatHeight} width={chatWidth} videoUrl={currentVideo} closeVideo={closeVideo}/>}
				{showTriggerList && <TriggerList triggers={allTriggers} triggerSequence={triggerSequence} close={() => {setShowTriggerList(false)}}/>}
				</div>
			</div>
			

		</div>
		)

}


export default ChatWidget;