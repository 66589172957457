import { analytics } from '../firebase/firebaseSetup'
import { logEvent } from "firebase/analytics";

const searchParams = new URLSearchParams(window.location.search);
const _session_id = searchParams.get("sessionId");
const _device_id = searchParams.get("deviceId");

const _start_time = Date.now();
let _last_event_time = _start_time;


export const sendEvent = (name, data, id) => {
	 if(window.dataLayer)
	 	window.dataLayer.push({event: name, interaction: data, organizationId: id});

	const _data = {...data, ...{organization_id: id}}

	sendManualAnalytics(_data, name);
	unichatsAnalytics(_data, name);


}

const sendManualAnalytics = async (_data, name) => {
	var firebase_app_id = "1:474275324461:web:8117df45546bc18b6f4ea4";
		var measurement_id = "G-04MNK6VC01";
		var api_secret = "60WI19YKRLe53sRqt5onxQ";

		var aux_time = Date.now()
		const msec = aux_time - _last_event_time;
		_last_event_time =  aux_time;

		_data = {..._data, ...{
			engagement_time_msec: msec.toString(),
			session_id: _session_id
		}}
		_data = {name: name, params: _data}

		var res = await fetch(`https://www.google-analytics.com/mp/collect?measurement_id=${measurement_id}&api_secret=${api_secret}`, {
		  method: "POST",
		  body: JSON.stringify({
		    client_id: _device_id,
		    events: [_data]
		  })
		});
}

const unichatsAnalytics = async (data, name) => {

	let event = {
		name: name,
		client_id: data.organization_id,
		session_id: _session_id,
		device_id: _device_id,
		type: data.name || "not-set",
		description: data.interaction_trigger_name || data.form || data.chat_link || "not-set",
	}

	try {
		var res = await fetch(`https://console.unifyapply.com/analytics/trackEvent`, {
			  method: "POST",
			  headers: {
			      "Content-Type": "application/json"
			    },
			  body: JSON.stringify(event)
		});	

		
	}
	catch(e) {
		console.log(e)
	}


}