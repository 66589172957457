import axios from "axios"

const HubspotSubmitForm = async (data) => {
	const url = `https://api.hsforms.com/submissions/v3/integration/submit/${data.hubspotIntegration.hubspotPortalId}/${data.hubspotIntegration.hubspotFormId}`;
	const body = {};
	body.submittedAt = Date.now();
	body.fields = []
	body.context = {pageUri: "https://widget.unifyapply.com", pageName: "Unicom Widget"}
	data.answers.map((a, i) => {
		if(a.fieldName)
			if(a.fieldName.includes("i_agree")){
				body.fields.push({"objectTypeId": "0-1", "name": a.fieldName, "value": true})
			}
			else if(a.fieldName != "" || a.fieldName)
				body.fields.push({"objectTypeId": "0-1", "name": a.fieldName, "value": a.answer})
	});

	body.legalConsentOptions = {
			    "consent": { 
			      "consentToProcess": true,
			      "text": "I agree to allow Alba to store and process my personal data.",
			      "communications": [
			        {
			          "value": true,
			          "subscriptionTypeId": 999,
			          "text": "I agree to receive marketing communications from Alba."
			        }
			      ]
			    }
			  };

	try {
		const res = await axios.post(url, body);
		return res.data;
	}
	catch(e) {
		return e.response.data;
	}

}

export {HubspotSubmitForm};