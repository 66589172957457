import {useState, useEffect} from 'react'

function ChatForm(props) {
	const [submitted, setSubmitted] = useState(false)
	const [email, setEmail] = useState("");


	async function submit(e) {

		e.preventDefault();
		if(submitted)
			return;

		//const form = e.target;
		//const formData = new FormData(form);

		const response = fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${props.portalId}/${props.formId}`, 
		{
			method: "Post",
			mode: "cors",
			headers: {
			    'Content-type': 'application/json; charset=UTF-8',
			  },
			body: JSON.stringify({
			  "submittedAt": Date.now(), 
			  "fields": [
			    {
			    "objectTypeId": "0-1",
			      "name": "email",
			      "value": email
			    }
			  ],
			  "context": {
			    "pageUri": "www.unifyapply.com/",
			    "pageName": "Unicom Widget"
			  },
			  "legalConsentOptions": {
			    "consent": { 
			      "consentToProcess": true,
			      "text": "I agree to allow Unify Apply to store and process my personal data.",
			      "communications": [
			        {
			          "value": true,
			          "subscriptionTypeId": 999,
			          "text": "I agree to receive marketing communications from Unify Apply."
			        }
			      ]
			    }
			  }
			})
		})

		setSubmitted(true)
		props.triggerSequence("","","END")
	}

	return(
		<form className="chat-form" method="post" onSubmit={submit}>
			<input placeholder="Enter your email" disabled={submitted} value={email} onChange={e => setEmail(e.target.value)} name="email"/>
			<button disabled={submitted} type="submit">{submitted? "Email Sent": "Send"}</button>
		</form>
		)
}


export default ChatForm;