import {useState, useEffect} from 'react'

function TextWithInput(props) {
	const [submitted, setSubmitted] = useState(false)
	const [input, setInput] = useState("")
	const [showError, setShowError] = useState(false)

	const handleSubmit = () => {
		let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		
		if(props.interaction.inputType)
			if(!re.test(input)) {
				setShowError(true)
				return;
			}

		if(input =="")
			return;

		setShowError(false)

		props.triggerSequence(input,props.next,"TRIGGER_INTERACTION")
		setSubmitted(true)
	}

	return(
		<div className="chat-form w-100"  >
			<input placeholder={props.interaction.placeholder} disabled={submitted} value={input} onChange={e => setInput(e.target.value)}/>
			{showError && <p className="d-inline-block"> <small className="text-danger">This is not an email.</small></p>}
			<button onClick={handleSubmit} className="submit" disabled={submitted} type="button">{props.interaction.button_cta}</button>
			
		</div>
		)
}


export {TextWithInput}