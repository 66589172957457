import './index.scss'
import {useState, useEffect} from 'react'


import {initData, submitForm} from './firebase/firebaseStoreHandler';
import {HubspotSubmitForm} from './hubspot';
import {sendEvent} from './tracking/dataLayerHandler'

import Interact from './components/interactWidget'
import Chat from './components/chatWidget'
//bMdvRssBpwIGJnntQngX
function UnifyCom() {
	const [chatActive, setChatActive] = useState(false);
	const [assistant, setAssistant] = useState(null)
	const [client, setClient] = useState(null)
	const [interactions, setInteractions] = useState([])
	const [prompts, setPrompts] = useState([])
	const [interactTrigger, setInteractTrigger] = useState(null)
	const [showPrompts, setShowPrompts] = useState(false)

	const [unicomKey, setUnicomKey] = useState()



	useEffect(() => {
		async function fetchInitialData() {

			const queryParameters = new URLSearchParams(window.location.search)
 			const key = queryParameters.get("key")


 			if(key) {
	 			setUnicomKey(key)
				const data = await initData(key)
	    		setAssistant(data.client.assistant)
	    		setClient({name: data.client.name, key: key, active: data.client.active})
	    		setInteractions([...data.interactions])
	    		setInitialPrompts(data.interactions);
	    	}
		}

		fetchInitialData()

		setTimeout(function() {
			setShowPrompts(true);
		}.bind(this), 5000)
    	
  	}, []);

  	useEffect(() => {
  		const queryParameters = new URLSearchParams(window.location.search)
 		const preview = queryParameters.get("preview")? true:false;
  		if(client) {
  		
  			if(client.active || preview) {
  				window.parent.postMessage("SMALL","*");
  				sendEvent("widget_shown", {}, unicomKey);
  			}
  			else {
  				window.parent.postMessage("INACTIVE","*");
  				sendEvent("widget_inactive_loaded", {}, unicomKey);
  			}
  		}
  	}, [client])

  	useEffect(() => {
  		const queryParameters = new URLSearchParams(window.location.search)
 		const preview = queryParameters.get("preview")? true:false;
  		if(!chatActive || preview) {
  			if(client)
	  			if(client.active) {
					if(showPrompts) {
						window.parent.postMessage("MED","*");
					}
					else
						window.parent.postMessage("SMALL","*");
				}
		}
		else {
			if(showPrompts)
				setShowPrompts(false)
		}

			
  	}, [showPrompts])

	function toggleChatActive() {
		if(!chatActive)
			window.parent.postMessage("FULL","*");
		else
			window.parent.postMessage("SMALL","*");
		if(!chatActive)
			sendEvent("interaction", {name: "open_chat"}, unicomKey);
		else
			sendEvent("interaction", {name: "close_chat"}, unicomKey);

		setChatActive(!chatActive);
		setShowPrompts(false);

	}

	async function formSubmit(data) {
		if(data.hubspotIntegration.hubspotPortalId) {
			const res = await HubspotSubmitForm(data)
			data.hubspotResponse = res;
		}
		const result = submitForm(unicomKey, data);
		return result;
	}

	function triggerInteraction(trigger) {
		sendEvent("interaction", {name: "prompt_trigger", interaction_trigger_name: trigger.trigger}, unicomKey);
		setInteractTrigger(trigger);
	}

	function setInitialPrompts(sequences) {
		const aux = [];
		const initialSequences = sequences.filter((inter) => {
			return inter.defaultTrigger;
		})
		for(var i = 0; i < 3; i++) {
			if(initialSequences[i])
				aux.push({trigger: initialSequences[i].trigger, sequenceId: initialSequences[i].id, type: initialSequences[i].triggerType})
		}
		setPrompts(aux);
	}



	function togglePrompts() {
		setShowPrompts(!showPrompts)
	}
	

	return ( 
		<div className="unify-interact-wrapper">
			{assistant? (<div>
				<Interact organizationId={unicomKey}  optionsVisible={showPrompts} togglePrompts={togglePrompts} triggerInteraction={triggerInteraction} prompts={prompts} toggleChat={toggleChatActive} activeChat={chatActive} assistant={assistant}/>
				<Chat organizationId={unicomKey} handleFormSubmit={formSubmit} trigger={interactTrigger} active={chatActive} assistant={assistant} client={client} flows={interactions}/></div>): null}	
		</div>
		)

}


export default UnifyCom