
import CloseImage from './close.svg'
import {useState, useEffect} from 'react'

function InteractWidget(props) {

	const [optionsActive, setOptionsActive] = useState(false);
	const [chatToggled, setChatToggled] = useState(false);

	function closeOptions() {
		props.togglePrompts()
	}

	function toggleChat() {
		setChatToggled(true);
		props.toggleChat();
	}

	function askChat(trigger) {

		setChatToggled(true);
		props.toggleChat();
		props.triggerInteraction(trigger)
	}



	return (
		<div>

			<div className={`unify-launcher ${optionsActive? "active": ""} ${props.activeChat? "chat-active": ""}`} onClick={toggleChat}>
				<img className="close" src={CloseImage}/>
				<img className="avatar" src={props.assistant.avatar}/>
			</div>
			{props.optionsVisible && (<div  onMouseEnter={() => setOptionsActive(true)} onMouseLeave={() => setOptionsActive(false)} className={`unify-launcher-options d-flex flex-column`}>
				<div className="greeting"><small> Hi 👋 I'm <strong>{props.assistant.name}</strong>, How can I help you?</small> <div onClick={closeOptions} className="close d-flex justify-content-center align-items-center"> <img src={CloseImage}/></div></div>
				<ul className="list-unstyled m-0">
					{props.prompts.map((prompt, i) => {
						return <li key={i} onClick={function() {askChat(prompt)}} className="option">{prompt.trigger}</li>
					})}
					<li onClick={function() {askChat({type: "SHOW_ALL_TRIGGERS", trigger: "Ask something else"})}}  className="option">I have another question.</li>
				</ul>
			</div>)}

		</div>
		)

}


export default InteractWidget;