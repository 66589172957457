import {useState, useEffect} from 'react'
import { X } from 'react-bootstrap-icons'

function EligibilityCheck(props) {
	const [submitted, setSubmitted] = useState(false);

	function submit(e){
		e.preventDefault();
		props.triggerSequence(null, props.onEligibilityEnd, null);
		props.close()
	}


	return (
			<div className="eligibility-wrapper">
				<div >
					<button className="trigger-list-close" onClick={() => {props.close(true)}}><X/></button>
					<h5 className="title">Eligibility Check</h5>
					<form className="unicom-form" onSubmit={submit}>
						<label> 
							First Name
							<input placeholder="First Name"/>
						</label>
						<label> 
							Last Name
							<input placeholder="Last Name"/>
						</label>
						<label> 
							Email
							<input placeholder="email"/>
						</label>
						<label> 
							Phone Number
							<input placeholder="+334893320"/>
						</label>

						<label> 
							Nationality
							<input placeholder="Nationality"/>
						</label>

						<label> 
							Country of Residence
							<input placeholder="Residence"/>
						</label>


						<label> 
							Highest educational level
						<select>
						  <option value="someOption">High School Degree</option>
						  <option value="someOption2">3 years Certificate</option>
						  <option value="otherOption">Bachelor Degree</option>
						  <option value="otherOption">Master Degree</option>
						  <option value="otherOption">Phd</option>
						</select>
						</label>

						<label> 
							Grade Point Average 
							<input placeholder="2-4"/>
						</label>
						<label> 
							English Proficiency
						<select>
						  <option value="someOption">My most recent degree was taught in english</option>
						  <option value="someOption2">I have done the IELTS/Toefl in the last 2 years</option>
						  <option value="otherOption">Haven't done any standarize test</option>
						  <option value="otherOption">Other</option>
						</select>
						</label>

						<button disabled={submitted} type="submit">Check my Eligibility</button>
					</form>
				</div>
			</div>
		)

}

export default EligibilityCheck;