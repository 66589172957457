import {useState, useEffect} from 'react'
import { X } from 'react-bootstrap-icons'

function TriggerList(props) {
	const [categories, setCategories] = useState([]);

	useEffect(() =>{
		var auxCategories = []
		props.triggers.map(trigger => {
			auxCategories.push(trigger.category)
		})
		auxCategories = [... new Set(auxCategories)];
		setCategories(auxCategories)
	}, [props.triggers])


	return(
		<div className="trigger-list-wrapper">
			<div >
			<button className="trigger-list-close" onClick={() => {props.close()}}><X/></button>
			<ul className="list-unstyled">
			<li className="title">Popular</li>
			{props.triggers.filter(t => {return t.defaultTrigger}).map((trigger, i) => {
				return <li className="interaction" key={i} onClick={() => {props.triggerSequence(trigger.trigger, trigger.sequenceId, trigger.type); props.close()}}>{trigger.trigger}</li>
			})}
			</ul>
			{categories.map((category, i) => {
				return (
						<ul key={i} className="list-unstyled">
							<li className="title" key={i}>About - {category}</li>
							{props.triggers.filter(t => {return t.category == category}).map((trigger, e) => {
								return <li className="interaction" key={e} onClick={() => {props.triggerSequence(trigger.trigger, trigger.sequenceId, trigger.type); props.close()}}>{trigger.trigger}</li>
							})}
						</ul>
					)
			})}
			</div>
		</div>
		)	
}

export default TriggerList;