import { doc, getDoc, getDocs, addDoc, collection, query, where } from "@firebase/firestore"
import { firestore } from "./firebaseSetup"


async function initData(key) {

	//Get Client Data
	const ref = doc(firestore, "organizations", key)

	const docSnap = await getDoc(ref);

	const data = docSnap.exists() ? docSnap.data() : null

  	if (data === null || data === undefined) return null

  	//Get Interactions
  	const interactions = [];
  	const interactionsRef = collection(firestore, "organizations", key, "flows")
  	
  	const querySnapshot = await getDocs(interactionsRef);

  	querySnapshot.forEach((doc) => {
	  // doc.data() is never undefined for query doc snapshots

	  interactions.push({id: doc.id, ...doc.data()})
	});

  	return { client: {key, ...data}, interactions: interactions }

}

const submitForm = async (organizationId, data) => {

	data.submittedAt = Date.now();
	try {
		const doc = await addDoc(collection(firestore, "organizations", organizationId, "forms_submissions"), data);
		return true
	}
	catch(err) {
		console.log(err)
		return false
	}
}




export {initData, submitForm}